/* This file is for normal CSS.. */

#gonnow {
  transition: 0.5s;
}
/* .main-content {
 margin-left:  3.33333%;
 margin-right: 3.33333%;
} */

body {
  /* margin-left: 3.33333%; 
  margin-right: 3.33333%; */
  padding: 0;
  background-image: url("../Static/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  height: 100vh;
  font-family: sans-serif;
}

/* Styles for the text gradient effect */
.gradient-text {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: #333;
}

.gradient-text::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #ff4800, #c5c5db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gradient-text:hover::before {
  opacity: 1;
  animation: gradientAnimation 1s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* gradient with fade*/
.gradient-bg {
  background: linear-gradient(
    to bottom,
    #010202,
    rgba(8, 2, 2, 0)
  ); /* #e7dddd */
}

/* select input submit button */
.button-bg {
  background: linear-gradient(to right, #d14881, #ca6931);
}

.zoom-effect {
  transition: transform 0.3s ease-in-out;
}

.zoom-effect:hover {
  animation: jerk 0.1s ease-in-out;
  transform: scale(1.1);
}

@keyframes jerk {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}



.nav-link {
  text-decoration: none;
  color: rgb(233, 169, 169);
  margin: 12px; /* Adjust the margin to add spacing between the navigation items */
  /* margin-right: 20px; */
  font-size: 24px; /* Adjust the font size as needed */
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #e8eaf3; /* Change the color on hover as desired */
}
