body {
  /* margin-left: 3.33333%; 
  margin-right: 3.33333%; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  
  code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
  }
  
  body {
  background-color: #162C4F;
  }
  
  .placeholder-blue-custom::placeholder {
    color: #1152bc; /* Adjust the color code as needed */;
  }
